import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "./axiosInstance";
// import { format, isValid, parse } from "date-fns";
import useAuthToken from "../hooks/useAuthToken";
import { LoaderContext } from "./LoaderContext";

import { APIURL, DUTY_TYPE, MAP_API_KEY } from "../Constants";

import resultBackgroungImg from "../images/background/2.jpg";
import carImageThumb from "../images/car-thumbs/car-image.png";

import mapboxgl from "mapbox-gl";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchPage = () => {
  const token = useAuthToken();
  const location = useLocation();
  const navigate = useNavigate();
  const { setHideLoader } = useContext(LoaderContext);
  const queryParams = useQuery();
  const encodedData = queryParams.get("data");
  const formData = encodedData ? JSON.parse(atob(encodedData)) : {};
  const [cars, setCars] = useState(null);
  const [carsLoading, setCarsLoading] = useState(null);
  const mapContainer = useRef(null);
  const map = useRef(null);
  mapboxgl.accessToken = MAP_API_KEY;

  const updateURL = (newData) => {
    const updatedData = { ...formData, ...newData };
    const encodedUpdatedData = btoa(JSON.stringify(updatedData));
    queryParams.set("data", encodedUpdatedData);
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  const getSearchCars = async () => {
    setHideLoader(false);
    setCarsLoading(true);
    try {
      const queryString = new URLSearchParams({
        ...formData,
        distance: formData?.distance,
      }).toString();
      const response = await axios.get(`${APIURL}search?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCars(response?.data?.cars);
      if (response.status === 200) {
        setHideLoader(true);
        setCarsLoading(false);
      }
    } catch (error) {
      setHideLoader(true);
      setCarsLoading(false);
      console.error("Error fetching cars:", error);
    }
  };

  const showDetails = (car) => {
    const encodedData = btoa(
      JSON.stringify({
        ...formData,
        ...car,
      }),
    );
    navigate(`/booking?data=${encodedData}`);
  };

  const getCoordinates = async (address) => {
    setHideLoader(false);
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json`,
        {
          params: {
            access_token: MAP_API_KEY,
            limit: 1,
          },
        },
      );
      setHideLoader(true);

      console.log("MYLOG address ", address, " response ", response);
      const coordinates = response.data.features[0].geometry.coordinates;
      return coordinates;
    } catch (error) {
      setHideLoader(true);
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const getDistance = async () => {
    setHideLoader(false);
    try {
      const originCoordinates = await getCoordinates(formData?.origin);
      const destinationCoordinates = await getCoordinates(formData?.destination);

      if (!originCoordinates || !destinationCoordinates) {
        console.error("Unable to get coordinates for origin or destination");
        return;
      }

      const response = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${originCoordinates[0]},${originCoordinates[1]};${destinationCoordinates[0]},${destinationCoordinates[1]}.json?access_token=${MAP_API_KEY}&geometries=geojson`,
      );
      setHideLoader(true);
      const route = response?.data?.routes[0]?.geometry?.coordinates;
      const distanceInMeters = response.data.routes[0].distance;
      const durationInSeconds = response.data.routes[0].duration;
      const hours = Math.floor(durationInSeconds / 3600);
      const minutes = Math.ceil((durationInSeconds % 3600) / 60);
      updateURL({
        distance: Math.ceil(distanceInMeters / 1000),
        distanceText: `${Math.ceil(distanceInMeters / 1000)} KM`,
        duration: durationInSeconds,
        durationText: `${hours} Hours ${minutes} Minutes`,
        route: route,
      });
      addRouteToMap(route);
    } catch (error) {
      setHideLoader(true);
      console.error("Error fetching distance:", error);
    }
  };

  const addRouteToMap = (route) => {
    console.log("MYLOG route", route);
    map.current.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: route,
        },
      },
    });

    map.current.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#3887be",
        "line-width": 5,
        "line-opacity": 0.75,
      },
    });

    const bounds = new mapboxgl.LngLatBounds();
    route.forEach((coord) => bounds.extend(coord));
    map.current.fitBounds(bounds, { padding: 50 });
  };

  useEffect(() => {
    if (formData?.origin && formData?.destination && !formData?.route) {
      getDistance();
    }
  }, [formData?.origin, formData?.destination]);

  useEffect(() => {
    if (formData && DUTY_TYPE?.[formData?.subType] && formData?.startAddress !== null) {
      console.log("MYLOG formData", formData);
      if (formData?.subType === "localPackages") {
        getSearchCars();
      } else if (formData?.distance > 0) {
        getSearchCars();
      }
    }
  }, [formData?.subType, formData?.startAddress, formData?.distance]);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [77.635, 12.934], // initial map center
      zoom: 10,
    });

    // Add route to map
    map.current.on("load", () => {
      if (formData?.route) {
        addRouteToMap(formData?.route);
      } else {
        getDistance();
      }
    });
  }, [formData?.route]);

  return (
    <>
      <section id="subheader" className="cars-result-header jarallax text-light">
        <img src={resultBackgroungImg} className="jarallax-img" alt="" />
      </section>
      <section id="section-cars">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="item_filter_group search_formlabel">
                <div ref={mapContainer} style={{ width: "100%", height: "500px" }} />
                {DUTY_TYPE?.[formData?.subType] && (
                  <p>
                    <span>Duty Type:</span> {DUTY_TYPE?.[formData?.subType] ?? "Invalid Type"}
                  </p>
                )}
                {formData?.startAddress && (
                  <p>
                    <span>From:</span> {formData?.startAddress}
                  </p>
                )}
                {formData?.endAddress && (
                  <p>
                    <span>To:</span> {formData?.endAddress}
                  </p>
                )}
                {formData?.distanceText && formData?.distance > 0 && (
                  <p>
                    <span>Distance:</span> {formData?.distanceText}
                  </p>
                )}
                {formData?.durationText && formData?.duration > 0 && (
                  <p>
                    <span>Duration:</span> {formData?.durationText}
                  </p>
                )}
                {formData?.localPackagesStr && (
                  <p>
                    <span>Package:</span> {formData?.localPackagesStr}
                  </p>
                )}
                {formData?.pickupDate && (
                  <p>
                    <span>Pickup Date & Time:</span> {formData?.pickupDate}
                  </p>
                )}
              </div>
            </div>

            <div className="col-lg-9">
              <div className="row">
                {!carsLoading &&
                  cars &&
                  cars?.map((car) => (
                    <div className="col-lg-12">
                      <div className="de-item-list mb30">
                        <div className="d-img">
                          <img src={carImageThumb} className="img-fluid" alt={car?.modelName} />
                        </div>
                        <div className="d-info">
                          <div className="d-text">
                            <h4>{car?.modelName}</h4>
                            <div className="d-atr-group">
                              <ul className="d-atr">
                                <li>
                                  <span>Seats:</span>4
                                </li>
                                <li>
                                  <span>Doors:</span>4
                                </li>
                                <li>
                                  <span>Fuel:</span>Petrol
                                </li>
                                <li>
                                  <span>Engine:</span>3000
                                </li>
                                <li>
                                  <span>Drive:</span>4x4
                                </li>
                                <li>
                                  <span>Type:</span>Hatchback
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="d-price text-center">
                          <span>
                            <i className="fa fa-rupee"></i> {car?.totalFare}
                          </span>
                          {car?.kmLimit && <p>Uptp {car?.kmLimit} Km</p>}
                          <button className="btn-main" onClick={() => showDetails(car)}>
                            Book Now
                          </button>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  ))}
                {carsLoading === true && cars !== null && (
                  <h4 className="text-center">No cars found</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchPage;
